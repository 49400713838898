import * as React from 'react';
// import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
// import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CircularProgress from '@mui/material/CircularProgress';

const defaultTheme = createTheme();

export default function SignIn() {
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState(null); // 追加: エラーステート
  const [emailError, setEmailError] = React.useState(null); // 追加: エラーステート
  const [passwordError, setPasswordError] = React.useState(null); // 追加: エラーステート
  const emailInputRef = React.useRef(null);

  React.useEffect(() => {
    const timerId = setTimeout(() => {
      emailInputRef.current.focus();
    }, 200);

    return () => clearTimeout(timerId);
  }, []); // Empty dependency array to run only once after component mounting


  const handleSubmit = async (event) => {
    event.preventDefault();
    const formData = new FormData(event.currentTarget);
    const email = formData.get('email');
    const password = formData.get('password');
    let valid = true;
    setError(null); // エラーをリセット
    setEmailError(null); // エラーをリセット
    setPasswordError(null); // エラーをリセット

    // 入力値の検証
    if (!isValidEmail(email)) { valid = false }
    if (!isValidPassword(password)) { valid = false }
    if (!valid) { return; }

    // エラーがなければ fetch を実行
    try {
      setLoading(true);
      const params = new URLSearchParams(window.location.search);
      const eventId = params.has('eventId')? params.get('eventId'): '';
      if (!eventId) {
        throw new Error(`購入対象の情報が不足しています`);
      }
      const response = await fetch('/auth', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ email, password, eventId }),
        credentials: 'include'
      });

      if (!response.ok) {
        const error = (await response.json()).error
        if(error.message === 'sorry, you are not plus member') {
          throw new Error(`プラス会員(有料会員)さまの先行販売期間です`);
        } else {
          throw new Error(`会員認証に失敗しました`);
        }
      }

      const responseData = await response.json();
      if (responseData) {
        
        const changeOrder = params.has('changeOrder')? params.get('changeOrder'): 0; 
        const responseToParams = { memberOfToken: responseData.memberOfToken, profile: responseData.profile, movieTickets: responseData.movieTickets, login: 2, changeOrder};
        const searchParams = new URLSearchParams(responseToParams);
        //const redirectTo = 'https://transaction.test.smart-theater.com/projects/aeoncinema-test/purchase/transaction/' + params.get('eventId') + '?' + searchParams.toString();
        const redirectTo = 'https://reserve.smart-theater.com/projects/aeoncinema-production/purchase/transaction/' + params.get('eventId') + '?' + searchParams.toString();

        window.location.href = redirectTo;
      } else {
        console.log('Login successful?');
      }
    } catch (error) {
      console.error('Login failed:', error.message);
      setError(error.message); // エラーをセット
    } finally {
      setLoading(false);
    }
  };

  // メールアドレスの形式を検証する関数
  const isValidEmail = (email) => {
    if (email === '') {
      setEmailError('メールアドレスを入力してください');
      return false;
    }

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      setEmailError('メールアドレスが不正です');
      return false;
    }
    return true;
  };

  // パスワードの形式を検証する関数
  const isValidPassword = (password) => {
    if (password === '') {
      setPasswordError('パスワードを入力してください');
      return false;
    }

    // 6〜12文字の半角英数字のみであることを検証
    const passwordRegex = /^[a-zA-Z0-9]{6,12}$/;
    if (!passwordRegex.test(password)) {
      setPasswordError('パスワードは6〜12文字の半角英数字のみです');
      return false;
    }
    return passwordRegex.test(password);
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <CssBaseline />
      <div style={{ position: 'relative', backgroundColor: '#f0f3f5' }}>
        {loading && (
          <div
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              backgroundColor: 'rgba(0, 0, 0, 0.5)',
              zIndex: 9999,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            <CircularProgress color="secondary" size={80} />
            <Typography variant="h6" component="div" sx={{ mt: 2, color: '#fff' }}>認証中...</Typography> {/* 認証中の文字 */}
          </div>
        )}
        <Container component="main">
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column',
              gap: '24px 0',
              minHeight: '100vh',
              paddingBottom: '5em'
            }}
          >
            <img src="./logo_eseki_mypage_pc.webp" alt="Wata Theatre Logo" style={{ marginBottom: '1rem', marginTop: '1rem' }} />
            <Card sx={{ width: '100%', maxWidth: 450, height: 'auto' }}>
              <CardContent sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '2rem' }}>
                <Typography component="h1" variant="h6" align="center">
                  ワタシアター会員以外の方
                </Typography>
                <Box
                  component="form"
                  noValidate
                  sx={{ mt: 1, width: '100%' }}
                >
                  <Button
                    fullWidth
                    variant="contained"
                    sx={{ mt: 3, mb: 2, padding: '16px 0', fontSize: 16, backgroundColor: '#b50081', '&:hover': {backgroundColor: '#ffffff', color: '#b50081'} }}
                    //onClick={() => window.location.href = "https://wata-test.aeoncinema.com/Aem/usr/usrRegi.do"}
                    onClick={() => window.location.href = "https://www.aeoncinema.com/watatheatre/"}
                  >
                    新規会員登録
                  </Button>
                  <Button
                    fullWidth
                    variant="contained"
                    sx={{ mt: 3, mb: 2, padding: '16px 0', fontSize: 16, backgroundColor: '#b50081', '&:hover': {backgroundColor: '#ffffff', color: '#b50081'} }}
                    onClick={() => {
                      const params = new URLSearchParams(window.location.search);
                      const changeOrder = params.has('changeOrder')? params.get('changeOrder'): 0; 
                      const responseToParams = { changeOrder };
                      const searchParams = new URLSearchParams(responseToParams);
                      if(params.has('eventId')){
                        //window.location.href = `https://transaction.test.smart-theater.com/projects/aeoncinema-test/purchase/transaction/${params.get('eventId')}?${searchParams.toString()}`
                        window.location.href = `https://reserve.smart-theater.com/projects/aeoncinema-production/purchase/transaction/${params.get('eventId')}?${searchParams.toString()}`
                      }
                    }}
                  >
                    会員にならずに購入
                  </Button>
                </Box>
              </CardContent>
            </Card>
            <Card sx={{ width: '100%', maxWidth: 450, height: 'auto' }}>
              <CardContent sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '2rem' }}>
                <img src="./logo_wata-theatre.png" alt="Wata Theatre Logo" style={{ marginBottom: '1rem' }} />
                {error && ( // 追加: エラーがあれば表示
                  <Typography variant="body1" color="error" align="center" gutterBottom>
                    {error}
                  </Typography>
                )}
                <Box
                  component="form"
                  onSubmit={handleSubmit}
                  noValidate
                  sx={{ mt: 1, width: '100%' }}
                >
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="email"
                    label="メールアドレス"
                    name="email"
                    autoComplete="email"
                    inputRef={emailInputRef}
                    error={emailError !== null} // エラーがある場合にエラーを表示
                    helperText={emailError} // エラーメッセージを表示
                  />
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    name="password"
                    label="パスワード"
                    type="password"
                    id="password"
                    autoComplete="current-password"
                    error={passwordError !== null} // エラーがある場合にエラーを表示
                    helperText={passwordError} // エラーメッセージを表示
                    inputProps={{ maxLength: 12 }}
                  />
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={{ mt: 3, mb: 2, padding: '16px 0', fontSize: 16, backgroundColor: '#b50081', '&:hover': {backgroundColor: '#ffffff', color: '#b50081'} }}
                  >
                    ログインして購入
                  </Button>
                  <Grid container>
                    <Grid item xs>
                      <Link
                      //href="https://wata-test.aeoncinema.com/Aem/usr/newPwFirst.do"
                      href="https://wata.aeoncinema.com/Aem/usr/newPwFirst.do"
                      variant="body2">
                        パスワードを忘れた方はこちら
                      </Link>
                    </Grid>
                  </Grid>
                </Box>
              </CardContent>
            </Card>
          </Box>
        </Container>
      </div>
    </ThemeProvider>
  );
}
